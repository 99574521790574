import { lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// side effects
import "lazysizes";
/**
 * lazysizes attribute change / re-initialization extension;
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/attrchange#lazysizes-attribute-change--re-initialization-extension
 */
import "lazysizes/plugins/attrchange/ls.attrchange";
// styles
import "./App.scss";
// utils
import { reportError, reportProblem } from "utils/frontend_error_reporting";
// components
import GeneralNavigation from "components/general-navigation";
import Layout from "components/layout";
import Loading from "components/Loading";
import { AMPLITUDE_PUBLIC_KEY } from "constants/amplitudePublicKey";

import { analytics } from "constants/segment";
import * as amplitude from "@amplitude/analytics-browser";
import { IS_DEV_ENV } from "constants/isDevEnv";

const MemberHome = lazy(() => import("components/Homepage/home"));
// const AboutUs = lazy(() => import("components/about-us"));
window.onerror = function (msg, url, lineNo, columnNo, error) {
  if (error) {
    reportError({
      reporter_module: "global_error_handler",
      context: { msg, url, lineNo, columnNo },
      err: error,
    });
  } else {
    reportProblem({
      reporter_module: "global_error_handler",
      context: { msg, url, lineNo, columnNo },
      frontend_error: msg.toString(),
    });
  }
  return false;
};

if (!IS_DEV_ENV) {
  amplitude.init(AMPLITUDE_PUBLIC_KEY);
}

export default function App() {
  analytics.page(useLocation());
  return (
    <Layout>
      <GeneralNavigation />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={MemberHome} />
          <Route exact path="/hey-there-psychiatrists" component={MemberHome} />
          {/* <Route exact path="/about-us" component={AboutUs} /> */}
        </Switch>
      </Suspense>
    </Layout>
  );
}
