import { fetchOrchidAPI } from "utils/api";

export const UNKNOWN_ERROR_MESSAGE: string =
  "Ooops! Something went wrong. We are working on it, please try again later, or contact us for help.";

type ReportProblemArgs = {
  reporter_module: string;
  context: any;
  frontend_error: string;
};

export async function reportProblem({
  reporter_module,
  context,
  frontend_error,
}: ReportProblemArgs): Promise<void> {
  console.error("error: ", reporter_module, context, frontend_error);
  try {
    const url = window.location.href;
    await fetchOrchidAPI(`/api/reporting/v1/frontend_error`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        live_dev_env: window.location.hostname === "localhost",
        url,
        reporter_module,
        context: JSON.stringify(context),
        frontend_error,
      }),
    }).then(async (response) => {
      if (!response.ok)
        console.error(
          "failed to report error, status:",
          response.status,
          "message:",
          response.text()
        );
    });
  } catch (err) {
    console.error("failed to report error:", err);
  }
}

type ReportErrorArgs = {
  reporter_module: string;
  context: any;
  err: Error;
};

export async function reportError({
  reporter_module,
  context,
  err,
}: ReportErrorArgs): Promise<void> {
  await reportProblem({
    reporter_module,
    context,
    frontend_error: `${err.message}\n${err.stack}`,
  });
}
