import { ReactComponent as BrandWhite } from "assets/svg/orchid-logo-white.svg";
import { ReactComponent as BrandBlue } from "assets/svg/orchid-logo.svg";
import { NavbarBrand, NavbarBrandProps } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import getClasses from "utils/getClasses";

const NavBrand = ({ className, ...props }: NavbarBrandProps) => {
  const { pathname } = useLocation();
  return (
    <NavbarBrand
      href="/"
      className={getClasses(styles["nav-brand"], className)}
      title={"orchid logo"}
      {...props}
    >
      {pathname === "/about-us" ? (
        <BrandBlue height={20} />
      ) : (
        <BrandWhite height={20} />
      )}
    </NavbarBrand>
  );
};

export default NavBrand;
