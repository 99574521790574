export function apiHostAndPath(path: string): string {
  const remoteHostname =
    window.location.hostname === "localhost"
      ? "orchidsquirrel.com"
      : window.location.hostname === "orchid.health"
      ? "orchid.exchange"
      : window.location.hostname;
  const hostname = remoteHostname.startsWith("www.")
    ? remoteHostname.substring(4)
    : remoteHostname;
  if (path.startsWith("/api/notes_secure_data/")) {
    return `https://n42.${hostname}${path}`;
  } else if (path.startsWith("/api/login")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/users")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/pubnub_grant")) {
    return `https://login.${hostname}${path}`;
  } else if (path.startsWith("/api/reporting")) {
    return `https://report.${hostname}${path}`;
  } else if (path.startsWith("/instant_messaging/ws")) {
    return `wss://ws.${hostname}${path}`;
  } else {
    return `https://api.${hostname}${path}`;
  }
}

export async function fetchOrchidAPI(
  resource: string,
  init: RequestInit = {}
): Promise<Response> {
  try {
    const response = await fetch(apiHostAndPath(resource), {
      ...init,
      credentials: "include",
      mode: "cors",
    });
    return response;
  } catch (err: any) {
    if (err.name === "AbortError") throw err;
    const init = { status: 503, statusText: "ServiceUnavailable" };
    const networkErr = new Response(err, init);
    return networkErr;
  }
  // return fetch(apiHostAndPath(resource), { ...init, credentials: "include", mode: 'cors' });
}
