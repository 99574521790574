import styles from "./styles.module.scss";
import LogoLoadingIcon from "../logo-loading-icon";
import getClasses from "utils/getClasses";

/**
 * renders an logo loader spinner that has margin
 * use as a whole page loader
 */
export const Loading = ({ className }: { className?: string }) => (
  <div className={getClasses(styles.loading, className)}>
    <LogoLoadingIcon className={styles["loading__spinner"]}/>
  </div>
);

export default Loading;
