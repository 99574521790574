export function getErrorDetail(response_text) {
  let error = response_text;
  try {
    const error_response = JSON.parse(error);
    if (typeof error_response.detail == "string") {
      error = error_response.detail;
    } else {
      const field_loc = error_response.detail[0].loc;
      error = `${error_response.detail[0].msg}: ${field_loc.join(".")}`;
    }
  } catch (_) {
    /* nothing to do here, we aren't interested in json parse error */
  }
  return error;
}
