import { useEffect, useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
// components
import NavBrand from "components/nav-brand";
// styles
import styles from "./styles.module.scss";
import getClasses from "utils/getClasses";
import "animate.css";

const GeneralNavigation = () => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const { pathname } = useLocation();
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        if (!scrolling) {
          setScrolling(true);
        }
      } else if (scrolling) {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling]);

  return (
    <Navbar
      expand="md"
      className={getClasses(
        // "shadow-sm",
        pathname === "/about-us" && styles["nav-bar-about-us"],
        styles["nav-bar"],
        isPhonePortrait && styles["nav-bar--phone"]
        // styles["navbar-fixed"],
        // scrolling && `animate__animated animate__fadeInDown`
      )}
    >
      <Container>
        <NavBrand />
      </Container>
    </Navbar>
  );
};

export default GeneralNavigation;
