import React, { ReactNode, Suspense } from "react";
import Loading from "components/Loading";

import { PhonePortraitContextProvider } from "./phone-portrait-context";

type Props = { children: NonNullable<ReactNode> };

const AppContextProvider = ({ children }: Props) => (
  <PhonePortraitContextProvider>
    <Suspense fallback={<Loading />}>{children}</Suspense>
  </PhonePortraitContextProvider>
);

export default AppContextProvider;
